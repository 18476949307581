.App {
  text-align: center;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),url(../public/Manrope-VariableFont_wght.ttf) format("truetype");
}



