.dashboard::-webkit-scrollbar{
    display: none;
}
.roomNumber {
   width: 180px;
   padding: 0.2rem;
   background: #F9F9FB;
   border-radius: 7px
}

.roomTotalHeader {
   font-family: 'Manrope';
   font-style: normal;
   font-weight: 600;
   font-size: 12.9008px;
   line-height: 19px;
   color: #000000;
}

.roomTotalNumber {
   font-family: 'Manrope';
   font-style: normal;
   font-weight: 800;
   font-size: 12.9008px;
   line-height: 19px;
   color: #000000;
   margin-left: 30px;

}